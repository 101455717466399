<template>
	<div class="invitation">
		<van-nav-bar
			title="代理人邀請碼"
			left-arrow
			class="qjc-agent-navbar"
			@click-left="$router.back()"
		></van-nav-bar>
		
		<div class="box">
			<div class="head">
				<div>
					<img v-if="sex" :src="require('@/assets/images/agent_'+ (sex==1?'nan':'nv') +'.png')" alt="头像">
					<span>{{ name }}</span>
				</div>
				
				<b v-if="type == '1'" @click="$router.push('/agent/invitation/giftCode')" class="gift qjc-fts-30 qjc-c-fff">生成禮品碼</b>
			</div>
			
			<ul class="nav qjc-fts-34 qjc-c-primary">
				<li class="active">邀请碼</li>
			</ul>
			
			<div id="code">
				<vue-qr 
					:logoSrc="require('@/assets/images/agent_miyinlogo.png')" 
					:text="codeUrl"
					:size="500"
					:margin="0"
					:logoScale=".25"
					:correctLevel="3"
				></vue-qr>
			</div>
			
			<div class="code-text">
				邀請碼：<span id="codeText">{{ codeText }}</span>
				<span @click="copy" data-clipboard-target="#codeText" class="copy qjc-fts-30 qjc-c-primary">複製</span>
			</div>
			
			<van-button @click="$router.push('/agent/invitation/share')" class="share qjc-c-fff qjc-bg-primary">
				<span class="qjc-vertical-m">立即分享</span>
				<img class="qjc-vertical-m" src="../../../assets/images/agent_login_arrow.png" alt="">
			</van-button>
			
			<div class="tip qjc-texta-l">
				您的邀請碼同時也是客戶購買疫境求真產品時可使用的<span class="qjc-c-primary">優惠碼</span>。 <br>
				客戶使用您的邀請碼購買產品才會計入您的銷售業績。
			</div>
			
		</div>
		
	</div>
</template>

<script>
	import Vue from 'vue'
	import { NavBar, Toast, Button } from 'vant'
	
	Vue.use(NavBar)
	   .use(Toast)
	   .use(Button);
	
	import { agentInfo } from '@/js/axios'
		
	import vueQr from 'vue-qr'
	import Clipboard from 'clipboard';
	
	export default{
		name: 'agentInvitation',
		data() {
			return {
				sex: '',// 1男2女
				name: '',
				codeUrl: '',
				codeText: '',
				
				// 用户类型
				type: ''
			}
		},
		components: {
			vueQr
		},
		mounted() {
			agentInfo().then(res => {
				if(res.code == 200){
					// 存储用户信息
					var userInfo = res.data; 
			
					if(userInfo.inviteCode){
						this.sex = userInfo.sex;
						this.name = userInfo.name;
						this.codeText = userInfo.inviteCode;
						
						this.type = userInfo.type;
						
						this.codeUrl = location.origin + '/' + this.$router.resolve({
							path: '/agent/login',
							query: {
								invitCode: this.codeText
							}
						}).href;
					}else{
						var that = this;
						Toast.fail({
							message: '您沒有購買疫境求真套餐，邀請碼功能暫時無法使用',
							getContainer: '.invitation',
							onClose: () => {
								that.$router.replace('/agent');
							}
						});
					}
			
				}else{
					Toast.fail(res.msg);
				}
			});
		},
		methods: {
			copy() {  
			  var clipboard = new Clipboard('.copy');
				clipboard.on('success', e => {  
					Toast.success("複製成功");
					// 释放内存  
					clipboard.destroy()  
				})  
				clipboard.on('error', e => {  
				  // 不支持复制  
				  Toast.fail('該瀏覽器不支持自動複製')  
				  // 释放内存  
				  clipboard.destroy()  
				})  
			}
		}
	}
</script>

<style scoped>
	.invitation >>> .van-toast{
		width: auto;
		text-align: left;
	}
</style>
<style lang="scss" scoped>
	.invitation{
		color: #3B4549;
		
		.box{
			.head{
				display: flex;
				height: 2.18rem;
				justify-content: space-between;
				align-items: center;
				padding: 0 0.64rem;
				background: url(../../../assets/images/agent_invitation_headbg.png) no-repeat;
				background-size: cover;
				
				div{
					display: flex;
					align-items: center;
					
					img{
						width: 1.08rem;
						height: 1.08rem;
						margin-right: 0.16rem;
					}
					span{
						font-size: 0.34rem;
						font-weight: 500;
						color: #fff;
					}
				}
				
				.gift{
					display: flex;
					align-items: center;
					font-size: 0.3rem;
					height: 0.62rem;
					padding: 0 0.32rem;
					border: 0.01rem solid #fff;
					border-radius: 0.31rem;
					
					&:active{
						opacity: .8;
					}
				}
			}
			.nav{
				width: 5.59rem;
				display: flex;
				margin: 0.72rem auto 0.4rem;
				border-bottom: 0.01rem solid #E1E3EB;
				padding: 0 0.22rem;
				
				li{
					position: relative;
					height: 0.76rem;
					line-height: 0.48rem;
					
					&::after{
						content: '';
						position: absolute;
						left: 50%;
						bottom: 0;
						width: 0;
						height: 0.06rem;
						border-radius: 0.06rem;
						background-color: #6883FB;
						transition: all .3s;
					}
					&.active::after{
						width: 100%;
						left: 0;
					}
				}
			}
			#code,
			.tip{
				width: 5.2rem;
			}
			#code{
				height: 5.2rem;
				margin: 0 auto 0.24rem;
				
				img{
					width: 100%;
					height: 100%;
				}
			}
			.code-text{
				display: inline-block;
				line-height: 0.6rem;
				background-color: #DADFF5;
				font-size: 0.3rem;
				padding: 0 0.30rem;
				border-radius: 0.28rem;
				margin: 0 auto;
				
				span.qjc-c-primary{
					margin-left: 0.24rem;
				}
			}
			.share{
				display: flex;
				align-items: center;
				justify-content: center;
				width: 5.16rem;
				height: 0.8rem;
				line-height: 0.8rem;
				font-size: 0.32rem;
				border-radius: 0.08rem;
				border-color: #6883FB;
				margin: 0.48rem auto 0;
				
				img{
					width: 0.59rem;
					height: auto;
					margin-top: -0.1rem;
					margin-left: 0.1rem;
				}
			}
		}
		.tip{
			margin: 0.32rem auto;
			color: #8C9699;
			font-size: 0.26rem;
			line-height: 0.34rem;
		}
	}
</style>
